<template>
  <div class="manage-cities animatedBox">
    <div class="container fluid">
      <!-- ============================ Filter ============================= -->
      <filter-panel
        ref="filterPanel"
        v-model="isFilterActive"
        @filter="filterData()"
        @clear="clearFilter()"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="City"
            name="filterCityName"
            type="text"
            @keyup.native.enter="filterData()"
          >
          </fd-input>
          <fd-select
            v-model="filter['state:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="State"
            name="filterState"
            :options="stateOptions"
          >
          </fd-select>
        </div>
      </filter-panel>

      <!-- ============================= Table ============================= -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="citiesTableColumns"
          :rows="citiesTableData"
          :totalRows="citiesTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptycity">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddCity">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button class="btn mr-1" @click="toEditCity(props.row.id)">
                <i class="fas fa-pen"></i>
              </button>
              <button class="btn danger" @click="deleteCities(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-cities",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      citiesTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "State",
          field: "state.name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      citiesTableData: [],
      citiesTablePagination: {},

      stateOptions: [],

      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "state:id": ""
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await Promise.all([
        this.getStates(),
        this.getAllCities({ limit: this.tableParams.perPage })
      ]);
      this.$store.commit("setIsLoading", false);
    },
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllCities();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllCities();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllCities().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterData() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllCities().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    toAddCity() {
      this.$router.push({ name: "ManageCitiesAdd" });
    },
    toEditCity(id) {
      this.$router.push({ name: "ManageCitiesEdit", params: { id: id } });
    },

    // ============================= API Related ===============================
    async getStates() {
      let data = await this.$store.dispatch("manageStates/getAllStates", {
        limit: 200
      });

      this.stateOptions = this._.cloneDeep(data.data);
    },
    async getAllCities() {
      this.$store.commit("setIsLoading", true);
      let filteredParam = this.$cleanQueryParam(this.filter);
      let data = await this.$store.dispatch("manageCities/getAllCities", {
        ...filteredParam,
        page: this.tableParams.page,
        limit: this.tableParams.perPage,
        include: "state"
      });

      this.citiesTableData = this._.cloneDeep(data.data);
      this.citiesTablePagination = this._.cloneDeep(data.meta.pagination);
      this.$store.commit("setIsLoading", false);
    },
    async deleteCities(id) {
      const c = confirm("Are you sure to delete?");

      if (c) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.$store.dispatch("manageCities/deleteCities", id);

          this.$store.commit("setIsLoading", false);
          this.getAllCities();
        } catch (error) {
          alert("An unexpected error occured. Please try again later.");
          this.$store.commit("setIsLoading", false);
          throw error;
        }
      }
    }
  }
};
</script>

<style>
.manage-cities {
}
</style>
