var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-cities animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('filter-panel',{ref:"filterPanel",on:{"filter":function($event){return _vm.filterData()},"clear":function($event){return _vm.clearFilter()}},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"City","name":"filterCityName","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData()}},model:{value:(_vm.filter['name[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'name[partial]', $$v)},expression:"filter['name[partial]']"}}),_c('fd-select',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"State","name":"filterState","options":_vm.stateOptions},model:{value:(_vm.filter['state:id']),callback:function ($$v) {_vm.$set(_vm.filter, 'state:id', $$v)},expression:"filter['state:id']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.citiesTableColumns,"rows":_vm.citiesTableData,"totalRows":_vm.citiesTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn mr-1",on:{"click":function($event){return _vm.toEditCity(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('button',{staticClass:"btn danger",on:{"click":function($event){return _vm.deleteCities(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptycity"},slot:"emptycity"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.toAddCity}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }